<template>
  <div class="container-fluid">
    <div class="row mt-2 mb-2">
      <div class="col-12">
        <div class="row">
          <div class="col-sm-8 col-12">
            <validation-observer ref="promotionRules">
              <validation-provider
                #default="{ errors }"
                name="bankName"
                rules="required"
              >
                <b-form-group
                  label="ส่วนลด"
                  label-for="h-first-name"
                  label-cols-md="4"
                >
                  <b-form-input
                    v-model="inputs.valueText"
                    type="number"
                    min="0"
                    max="99"
                    :state="errors.length > 0 || parseInt(inputs.valueText) > 99 || parseInt(inputs.valueText) < 0 ? false:null"
                    placeholder="โปรดระบุส่วนลด"
                    @input="updateValue"
                  />
                  <small
                    v-if="errors.length > 0 || parseInt(inputs.valueText) > 99 || parseInt(inputs.valueText) < 0 "
                    class="text-danger"
                  >{{ texterror }}</small>
                </b-form-group>
              </validation-provider>
            </validation-observer>
          </div>
          <b-col cols="12 mb-2">

            <b-button
              :variant="inputs.id===''?'primary':'warning'"
              class="mr-1"
              @click="onClickCommit"
            >
              {{ inputs.id=== ''?'เพิ่ม':'แก้ไข' }}
            </b-button>
          </b-col>
        </div>
        <div class="">
          <b-table
            :sticky-header="true"
            :no-border-collapse="false"
            responsive
            :items="item"
            :fields="field"
            class="mb-0"
          >
            <template #cell(name)="data">
              {{ data.item.name }}
              <!-- <p>{{ data.item.price.$numberDecimal | toCurrency }}</p> -->
            </template>
            <template #cell(bankName)="data">
              {{ data.item.bankName | nameAccount }}
            </template>
            <template #cell(button)="data">
              <div class="d-flex">
                <button
                  class="btn btn-sm btn-warning mr-1"
                  type="button"
                  @click="onClickEdit(data.item._id,data.item.value.$numberDecimal)"
                >
                  แก้ไข
                </button>
                <button
                  class="btn btn-sm btn-danger"
                  type="button"
                  @click="ondelete(data.item._id)"
                >
                  ลบ
                </button>
              </div>
            </template>
          </b-table>
        </div>

      </div>
    </div>
  </div>

</template>

<script>
import {
  BRow, BCol, BTable, BFormGroup, BFormSelect, BPagination, BModal, BButton, VBModal, BCardBody, BFormInput,
  BForm
} from 'bootstrap-vue'

import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  name: 'PromotionAdd',
  components: {
    BRow,
    BCol,
    BTable,
    BFormGroup,
    BFormSelect,
    BPagination,
    BModal,
    BButton,
    VBModal,
    BCardBody,
    BFormInput,
    BForm,
    ValidationProvider,
    ValidationObserver,
    required
  },
  data () {
    return {
      inputs: {
        id: '',
        name: '',
        valueText: '',
        value: null,
        type: 'D'
      },
      optiondiscount: [],
      checkAccountSelect: true,
      item: [],
      field: [
        {
          key: 'name', label: 'ส่วนลด', thStyle: 'min-width: 150px'
        },
        { key: 'button', label: '', thStyle: 'width: 150px', tdClass: 'text-center', thClass: 'text-center' }

      ]
    }
  },
  computed: {
    texterror () {
      // eslint-disable-next-line radix
      return parseInt(this.inputs.valueText) > 99 || parseInt(this.inputs.valueText) < 0 ? 'ระบุค่าให้ถูกต้อง' : 'กรุณาระบุส่วนลด'
    }
  },
  watch: {
    'inputs.valueText': {
      handler (val) {
        this.inputs.name = `ส่วนลด ${val} %`
        // eslint-disable-next-line radix
        this.inputs.value = parseInt(val)
      }
    }
  },
  created () {
    this.onLoad()
  },
  methods: {
    updateValue (event) {
      if (event > 99) {
        this.inputs.valueText = event.toString()
      }
      this.$forceUpdate()
    },
    onClickEdit (id, value) {
      this.inputs.id = id
      this.inputs.valueText = value.toString()
    },
    onClickCommit () {
      if (this.inputs.id === '') {
        this.add()
      } else {
        this.edit()
      }
    },
    ondelete (id) {
      console.log(id)
      this.$store
        .dispatch('promotion/DeletePromotion', { id })
        .then(response => {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'โปรโมชั่น',
              icon: 'CoffeeIcon',
              variant: 'success',
              text: response.data.message
            }
          })
          this.onLoad()
          this.$emit('getPromotion')
        }).catch(error => {
          console.log(error)
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'โปรโมชั่น',
              icon: 'CoffeeIcon',
              variant: 'danger',
              text: error.data.message
            }
          })
        })
    },
    edit () {
      this.$refs.promotionRules.validate().then(success => {
        if (success) {
          if (this.inputs.value < 0 || this.inputs.value > 99) return
          console.log(this.inputs)
          this.$store
            .dispatch('promotion/editPromotion', this.inputs)
            .then(response => {
              this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: 'โปรโมชั่น',
                  icon: 'CoffeeIcon',
                  variant: 'success',
                  text: response.data.message
                }
              })
              this.$emit('loaderPromotion')
              this.$emit('getPromotion')
            }).catch(error => {
              console.log(error)
              this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: 'โปรโมชั่น',
                  icon: 'CoffeeIcon',
                  variant: 'danger',
                  text: error.data.message
                }
              })
            })
        }
      })
    },
    add () {
      this.$refs.promotionRules.validate().then(success => {
        if (success) {
          if (this.inputs.value < 0 || this.inputs.value > 99) return

          this.$store
            .dispatch('promotion/AddPromotion', this.inputs)
            .then(response => {
              this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: 'โปรโมชั่น',
                  icon: 'CoffeeIcon',
                  variant: 'success',
                  text: response.data.message
                }
              })
              this.$emit('loaderPromotion')
              this.$emit('getPromotion')
            }).catch(error => {
              console.log(error)
              this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: 'โปรโมชั่น',
                  icon: 'CoffeeIcon',
                  variant: 'danger',
                  text: error.data.message
                }
              })
            })
        }
      })
    },
    onLoad () {
      this.optiondiscount = [{ value: '', text: 'โปรดเลือกส่วนลด' }]
      for (let i = 0; i < 100; i++) {
        this.optiondiscount.push({ value: i.toString(), text: `ส่วนลด ${i} %` })
      }
      this.$store.dispatch('promotion/getPromotionAll').then(res => {
        this.item = res.data.items
      })
    }
  }
}
</script>
